<header class="main-header sticky-top">
  <nav class="navbar navbar-light navbar-expand-lg top-navbar bg-white shadow-sm">
    <div class="container-fluid">
      <div class="navbar-brand py-4"><img src="/assets/images/logotipo-rg-punto-a-punto.svg" alt="Punto a punto" class="logo-header"></div>
    </div>
  </nav>
</header>
<div class="container pt-4 pb-3">
  <h2 class="section-title">Condiciones generales de uso</h2>
</div>
<div class="container legal-text-container legal-text">
  <p>El sitio web www. puntoapuntorenault.com (en adelante, la &quot;<strong>Web</strong>&quot;), operado por Renault España Comercial, S.A. con domicilio en Parque Empresarial Avenida de Europa, Avenida de Europa nº 1, Edificio A, 28108-ALCOBENDAS (Madrid) y C.I.F. A-47.329.180 (en adelante, &ldquo;<strong>RENAULT</strong>&rdquo;) ha sido creado para ofrecer a los usuarios (en adelante, el &quot;<strong>Usuario</strong>&quot;) la oportunidad de acceder a los servicios de fidelización de Renault Group, la visualización y consulta de información de los accesorios de las distintas familias que se indican, así como a las ventajas asociadas a su compra (en adelante, el &quot;<strong>Servicio</strong>&quot;), de conformidad con estos Términos y Condiciones Generales Uso que constituyen el vínculo contractual entre RENAULT y el Usuario.</p>
  <p>Para la compra de piezas online la Web le redirigirá a Rparts (titularidad de Renault SAS) -fuera del entorno de la Web- para llevar a cabo esta clase de transacciones de forma segura, remota y directa, teniendo que darse de alta y aceptar los Términos y Condiciones de Uso y de Venta que regulen esa plataforma. Por lo tanto, RENAULT no asume ninguna responsabilidad derivada de las operaciones que el Usuario pueda realizar en RParts. Asimismo, RENAULT no asume ninguna responsabilidad por los pagos realizados a través del proveedor de servicios de pago de RParts. Cualquier disputa, reclamación o litigio relacionado con el abono y/o débito de la compra realizada por el Usuario y, en general, cualquier mal funcionamiento en el proceso de pago debe dirigirse directamente a Renault SAS o el proveedor de servicios de pago.</p>
  <div class="border-box-grey info-icon my-4" role="alert">
    <div class="alert-text">
      <h4>Importante</h4>
      <p class="lead text-info"><strong>Si no aceptas las Condiciones Generales de Usuario no podrás acceder a tu Área Privada ni <u>ver los puntos</u> o el <u>Catálogo</u> entre otros.</strong></p>
      <p class="lead">El usuario deberá leer atentamente estas condiciones generales antes de aceptar y acceder o utilizar el servicio.</p>
    </div>
  </div>
  <p><strong>Definiciones</strong></p>
  <p>&quot;<strong>Marcas</strong>&quot; se refiere a todas las marcas propiedad de RENAULT, sus filiales o sus colaboradores, por ejemplo, RENAULT, DACIA, MOTRIO, IXELL, IXTAR o cualquier otra que se pueda indicar.</p>
  <p>&quot;<strong>Contenido</strong>&quot; significa, pero no se limita a, la estructura de la Web, contenido editorial, artículos, textos, dibujos, ilustraciones, imágenes, fotografías, cartas gráficas, marcas, logotipos, acrónimos, nombres de empresas, obras visuales, obras audiovisuales, obras multimedia, contenido visual, contenido de audio y sonido, así como cualquier otro contenido y / o elemento presente en la Web.</p>
  <p>&quot;<strong>Usuario</strong>&quot; significa las Agencias Renault/Dacia Servicio, Renault/Dacia Servicio, Talleres Motrio y Talleres Independientes. Todos ellos se podrán inscribir en el Servicio a través del Concesionario / Reparador Autorizado de Renault y Dacia de su zona a través del formulario que se encuentra en el Web.</p>
  <p>&quot;<strong>Ventajas</strong>&rdquo; significa la obtención de puntos por compras y la posibilidad de canjearlos por regalos. Promociones exclusivas que te permitirán ahorrar en tus compras de piezas de recambio y accesorios y sumar puntos. Acceso al Catálogo de regalos Punto a Punto. Retos con los que conseguir experiencias y viajes exclusivos. Novedades e información relevante de tu interés.</p>
  <p>&ldquo;<strong>CGU</strong>&rdquo; significa los términos y condiciones de acceso y uso de la Web por parte de los Usuarios.</p>
  <p class="ordered-header"><strong>Finalidad del Servicio</strong></p>
  <p>El objetivo de la Web es promover un servicio de fidelización de los Usuarios, así como descubrir una selección de productos y servicios de RENAULT a través de la Web o de una redirección a otras aplicaciones o sitios publicados por RENAULT o por terceros editores (que requieran conocimiento y la aceptación de las CGU o condiciones contractuales correspondientes).</p>
  <p>Para ello, la Web pone a disposición de los Usuarios los medios técnicos y el entorno necesarios para el acceso a la información y consulta sobre piezas de recambio y accesorios, así como a las Ventajas asociadas a su compra.</p>
  <p>Con el alta del Usuario en el Servicio, éste se podrá beneficiar de las Ventajas que resultan en la obtención de puntos, bien a través de la compra de piezas de recambios/accesorios de las familias propuestas, bien a través de promociones mensuales que se vayan indicando en la Web, para canjearlos en un catálogo de regalos.</p>
  <p class="ordered-header"><strong>Acceso al Servicio </strong></p>
  <p>En particular, se puede acceder al Servicio en la Web desde (i) un smartphone compatible y (ii) un ordenador o tablet con acceso a Internet. Las comunicaciones relacionadas con el Servicio podrían tener lugar a través de varios medios que se disponen en la Web.</p>
  <p class="ordered-header"><strong>Cuenta / Perfil privado</strong></p>
  <p>Para abonarse al Servicio es necesario que el Usuario cree una cuenta personal en la Web cumplimentando la información que se le solicite y siguiendo los pasos designados al efecto. Una vez completado el registro, el usuario recibirá un correo electrónico.</p>
  <p>En el proceso de inscripción el usuario introduce su correo electrónico y de forma automática recibe un código vía email. Se ha implementado un proceso de autenticación mediante código de un solo uso (OTP). Este código de un sólo uso es válido por 60 minutos desde el momento en que se genera. Después de ese tiempo, el código expirará y será necesario solicitar uno nuevo.</p>
  <p>El Usuario se compromete a mantener en secreto su correo electrónico y a no revelarlos de ningún modo a nadie. Se informa al Usuario que cualquier divulgación a un tercero es de su exclusiva responsabilidad, manteniendo a RENAULT indemne.</p>
  <p>El Usuario es el único responsable del acceso a su perfil privado creado en la Web. El Usuario se obliga a notificar inmediatamente a la RENAULT cualquier uso fraudulento o no autorizado de su perfil privado.</p>
  <p class="ordered-header"><strong>Derecho de uso</strong></p>
  <p>El Usuario podrá acceder libremente a la información general ofrecida por la Web.</p>
  <p>RENAULT concede al Usuario un derecho personal, no exclusivo, revocable, intransferible, ilimitado y gratuito de uso del Servicio, sus Contenidos y aplicaciones únicamente para sus propias necesidades en relación con el uso de la Web y de acceso al Servicio, estando excluida cualquier otra finalidad.</p>
  <p>El Usuario tiene estrictamente prohibido acceder, descompilar, descomponer y/o utilizar los elementos informáticos de la Web, el Servicio, sus Contenidos y aplicaciones.</p>
  <p>El Usuario no adquiere ningún derecho sobre los Servicios, el Contenido y/o las aplicaciones, más allá de los que se conceden dentro del estricto marco del presente documento.</p>
  <p>Además, el Usuario reconoce que no puede eludir, retirar, anular o modificar las medidas de seguridad del Servicio y/o la Web y que no puede en ningún caso eliminar los avisos de derechos de autor del Servicio.</p>
  <p class="ordered-header"><strong>Obligaciones del usuario</strong></p>
  <p>El Usuario se compromete a:</p>
  <ul>
    <li>utilizar la Web y el Servicio exclusivamente para un uso previsto en el presente documento;</li>
    <li>informar a RENAULT si tiene conocimiento de algún acto de piratería y/o de un uso ilegal o extracontractual de la Web;</li>
    <li>no reproducir, directamente o no, ni siquiera temporal y/o parcialmente, la totalidad o parte de la Web, por cualquier medio y en cualquier forma;</li>
    <li>no utilizar ningún software o proceso diseñado para copiar la Web o el Servicio;</li>
    <li>no adaptar, modificar, traducir, transcribir, compilar, descompilar, ensamblar, desensamblar todo o parte de la Web;</li>
    <li>no exportar la Web y/o fusionarla con otros programas informáticos;</li>
    <li>no crear obras derivadas;</li>
    <li>no eliminar ningún elemento de identificación de derechos de autor, marcas comerciales o cualquier otra indicación de derechos reservados;</li>
    <li>no asignar o transferir el Servicio a un tercero no autorizado.</li>
    <li>no hacer ningún uso contrario a la moral y al orden público (como, pero no limitado a, cualquier contenido de naturaleza racial, pornográfica o sexual).</li>
  </ul>
  <p class="ordered-header"><strong>Disponibilidad del Servicio</strong></p>
  <p>RENAULT se compromete a hacer todo lo posible para garantizar y asegurar el acceso y el uso de la Web. Se trata de una obligación de medios, asumida teniendo en cuenta los riesgos técnicos, por lo que en ningún caso la Web puede garantizar un uso continuo y sin perturbaciones.</p>
  <p>La Web o su acceso pueden interrumpirse, suspenderse o modificarse en cualquier momento, en particular en caso de fuerza mayor o de ocurrencia de un evento fuera del control de RENAULT, y sujeto a cualquier avería e intervención de mantenimiento necesaria para el correcto funcionamiento de la Web o para su mejora (mantenimiento, actualizaciones de software de los sistemas, etc.), o mal funcionamiento de la red de (tele)comunicaciones.</p>
  <p class="ordered-header"><strong>Responsabilidad </strong></p>
  <p>En la medida permitida por las leyes y reglamentos aplicables, RENAULT no asume ninguna responsabilidad por el uso de la Web por parte del Usuario y no ofrece ninguna garantía, expresa o implícita, con respecto al acceso, velocidad, rendimiento o calidad del Servicio o en cuanto al uso que haría el Usuario de los datos y/o información accesible a través de la Web, o en cuanto a la compatibilidad de la Web y el Servicio para un uso particular.</p>
  <p>RENAULT no garantiza que la Web estará disponible o estará libre de errores, defectos u omisiones, ni que no será objeto de daños o ataques, en particular virus, que puedan provocar fallos de funcionamiento, interrupciones del servicio, averías o pérdidas de datos o información.</p>
  <p>El acceso y utilización de la Web son por cuenta y riesgo del Usuario. El Usuario es responsable de tomar todas las medidas apropiadas para proteger sus propios datos y/o softwares almacenados en su equipo (portátil, móvil o tablet) utilizado para acceder a la Web.</p>
  <p>En cualquier caso, RENAULT quedará exonerada de cualquier responsabilidad:</p>
  <ul>
    <li>en caso de incumplimiento por parte del Usuario de estas CGU;</li>
    <li>en caso de mal funcionamiento de la Web debido a una causa, voluntaria o involuntaria, atribuible al Usuario o atribuible a un tercero o a un acto malicioso, que surja de un mal funcionamiento de un software, smartphone / tablet, interfaz o cualquier otro producto o suministro del Usuario;</li>
    <li>en caso de mal funcionamiento y/o fallo de la red de comunicación del Usuario, cualquiera que sea la causa;</li>
    <li>en caso de cualquier daño directo o indirecto derivado del uso de la Web.</li>
    <li>en caso de cambios legislativos o reglamentarios que afecten a la Web o al Servicio;</li>
    <li>en caso de incumplimiento de los términos de uso específicos de las aplicaciones y/o sitios web publicados y editados por terceros, o de incumplimiento o cumplimiento parcial de las obligaciones por parte de terceros;</li>
  </ul>
  <p>RENAULT no se hace responsable del contenido (en particular textos, vídeos, imágenes, fotografías, etc.) y/o productos y/o servicios ofrecidos por aplicaciones y/o sitios web publicados y editados por terceros, los cuales son responsabilidad de esos editores.</p>
  <p class="ordered-header"><strong>Datos personales</strong></p>
  <p>Al utilizar el Servicio, sus datos personales se procesan en las condiciones detalladas en la política de protección de datos personales de RENAULT, accesible aquí <a href="https://www.renault.es/politica-privacidad" target="_blank">https://www.renault.es/politica-privacidad</a></p>
  <p>En el marco de la presente relación contractual, RENAULT será responsable de los datos personales del Usuario.</p>
  <p>RENAULT pertenece a un grupo multinacional, por lo que Renault SAS, como empresa matriz, bajo ciertas condiciones y casos específicos como puede ser la prestación de determinados servicios &ldquo;on line&rdquo; también actuará como responsable del tratamiento.</p>
  <p>Los datos de los Usuarios registrados serán utilizados para gestionar y dar se alta un perfil privado en la Web.</p>
  <p>El cliente tendrá derecho a acceder, rectificar y suprimir los datos personales, así como otros derechos, como se explica en la política de protección de datos de RENAULT que se puede consultar en <a href="https://www.renault.es/politica-privacidad" target="_blank">https://www.renault.es/politica-privacidad</a>.</p>
  <p class="ordered-header"><strong>Propiedad intelectual e industrial</strong></p>
  <p>Parte o la totalidad del Servicio y la Web está protegido por derechos de propiedad intelectual e industrial, que son propiedad de RENAULT o de terceros que hayan autorizado a RENAULT a utilizarlos.</p>
  <p>Cualquier reproducción, representación, uso o modificación, por cualquier medio y en cualquier soporte, de todo o parte de la Web o el Servicio sin haber obtenido la autorización previa de RENAULT, está estrictamente prohibida y constituye un delito contra la propiedad intelectual y/o industrial. Los nombres, los eslóganes y, en general, las marcas presentes en la Web son marcas registradas de RENAULT y siguen siendo propiedad suya. Las marcas de terceros presentes en la Web son propiedad de los terceros que han autorizado a RENAULT a reproducirlas y utilizarlas. Su reproducción, idéntica o por imitación, o su utilización sin la autorización previa de RENAULT o de sus respectivos propietarios está estrictamente prohibida y constituye una infracción de los derechos de propiedad intelectual.</p>
  <p>El Usuario no puede copiar, modificar, reproducir, representar, traducir total o parcialmente, de ninguna forma, la información y los elementos del Servicio y los que figuran en la Web, ni intervenir en estos, ni actuar de forma que infrinja los derechos de propiedad intelectual de RENAULT y de terceros que hayan autorizado a RENAULT a utilizarlos.</p>
  <p>El Usuario no adquiere ningún derecho de propiedad de ningún tipo sobre know-how, nombres corporativos, nombres comerciales, marcas, derechos de autor, logotipos, patentes y cualquier otro derecho de propiedad intelectual y/o industrial relacionado con RENAULT.</p>
  <p class="ordered-header"><strong>Actualización de los términos y condiciones</strong></p>
  <p>Las CGU aplicables son las vigentes en la fecha de conexión y uso de la Web y del Servicio por parte del Usuario.</p>
  <p>RENAULT se reserva el derecho de modificar y actualizar estas CGU, en cualquier momento y sin previo aviso a los Usuarios.</p>
  <p>Si es necesario, se requerirá al Usuario que realice cambios y actualizaciones tan pronto como se publiquen en la Web, siendo responsabilidad del Usuario consultar las CGU vigentes en el momento de cada conexión.</p>
  <p class="ordered-header"><strong>Ley aplicable</strong></p>
  <p>Las presentes CGU, así como todos los derechos y obligaciones generados en relación con el uso del Servicio o relacionados con el mismo, están sujetos a la legislación española y se interpretan y aplican de conformidad con ella.</p>
  <p>Cualquier disputa relacionada con el acceso y uso del Servicio debe ser primero objeto de un intento de solución amistosa entre las partes (el Usuario deberá enviar una reclamación por escrito al Departamento de Relaciones con el Cliente).</p>
  <p>Cualquier litigio relativo al acceso y la utilización de la Web deberá ser objeto de un intento de solución amistosa entre las partes antes de someterse a los juzgados y tribunales de Madrid, a los que las partes atribuyen competencia exclusiva.</p>
  <p class="ordered-header"><strong> Miscelánea</strong></p>
  <p><strong>Sin renuncia</strong><br>
    El hecho de que una de las partes no invoque una cláusula de estas CGU no se interpretará como una renuncia temporal o definitiva al ejercicio de la disposición en cuestión, que permanecerá vigente.</p>
  <p><strong>Nulidad de una cláusula</strong><br>
    Si una o más disposiciones de estas CGU se declaran inválidas, nulas o no escritas de conformidad con la normativa aplicable, cambios normativos o tras una resolución de un tribunal competente, el resto de las disposiciones permanecerá en pleno vigor y efecto.</p>
  <p><strong>Fuerza mayor</strong><br>
    RENAULT no se hace responsable de ningún evento que tenga las características de fuerza mayor y resulte en un mal funcionamiento del Servicio.</p>
  <p>Expresamente se consideran como casos de fuerza mayor, además de los habitualmente recogidos por la jurisprudencia nacional aplicable, restricciones y modificaciones gubernamentales y legales, fallos informáticos y bloqueos de telecomunicaciones, fallos de servidores o redes de comunicación o mal funcionamiento informático (&quot;bugs&quot;, virus, malware, intrusiones).</p>
  <div class="custom-button-group align-right mt-5">
    <div class="form-check form-switch form-switch-right">
      <input [(ngModel)]="acceptCrm" class="form-check-input" type="checkbox" id="acceptCrmSwitch">
      <label class="form-check-label" for="acceptCrmSwitch">Quiero recibir comunicaciones comerciales de Renault España Comercial a través de Punto a Punto.</label>
    </div>
  </div>
  <div class="custom-button-group align-right mt-1">
    <div class="form-check form-switch form-switch-right">
      <input [(ngModel)]="acceptCrmR1" class="form-check-input" type="checkbox" id="acceptCrmR1Switch">
      <label class="form-check-label" for="acceptCrmR1Switch">Quiero recibir comunicaciones comerciales de mi concesionario Renault/Dacia con el que mantengo una relación comercial a través del programa Punto a Punto.</label>
    </div>
  </div>
  <div class="custom-button-group align-right mt-5">
    <button class="btn-black-border" (click)="cancel()">Cancelar</button>
    <button class="btn-dimgrey" (click)="setConditions()">Aceptar condiciones</button>
  </div>
</div>