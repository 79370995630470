import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileValidationService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body instanceof FormData) {
      const files: File[] = [];
      req.body.forEach((value, key) => {
        if (value instanceof File) {
          files.push(value);
        }
      });

      const forbiddenExtensions = [
        '.aspx', '.exe', '.bat', '.cmd', '.sh', '.js', '.vbs', '.ps1',
        '.pif', '.scr', '.msi', '.msp', '.gadget', '.hta', '.jar',
        '.docm', '.xlsm', '.pptm', '.iso', '.img', '.bin',
        '.reg', '.dll', '.sys', '.com', '.cpl', '.msc'
      ];

      for (const file of files) {
        const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
        if (forbiddenExtensions.includes(fileExtension)) {
          return throwError(() => new HttpErrorResponse({ error: `No se permiten archivos con la extensión ${fileExtension}` }));
        }
      }
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }
}