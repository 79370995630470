import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import es from '@angular/common/locales/es';
import { AppRoutingModule } from './app-routing.module';
import { registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { PrefooterComponent } from './components/shared/prefooter/prefooter.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { PointsComponent } from './components/points/points.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { PatternsComponent } from './components/develop/patterns/patterns.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthJwtInterceptorService } from './services/auth-jwt-interceptor.service';
import { Helpers } from './helpers/helpers';
import { ToastrModule } from 'ngx-toastr';
import { CustomValidators } from './helpers/custom-validators';
import { Select2Module } from 'ng-select2-component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { BreadcrumbsComponent } from './components/shared/breadcrumbs/breadcrumbs.component';
import { SharedModule } from './components/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ConditionsAcceptComponent } from './components/conditions-accept/conditions-accept.component';
import { FileValidationService } from './services/file-interceptor';

registerLocaleData(es);

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    PrefooterComponent,
    FooterComponent,
    BreadcrumbsComponent,
    CookiesPolicyComponent,
    ConditionsAcceptComponent,
    PointsComponent,
    PatternsComponent,
    ...APP_CONTAINERS
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    Select2Module,
    NgbModule,
    SharedModule,
    ToastrModule.forRoot(),
    FormsModule
  ],
  providers: [
    Helpers,
    CustomValidators,
    DatePipe,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthJwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FileValidationService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
